















import { Component, Vue } from "vue-property-decorator";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import nueva_platoModule from "@/store/modules/visualizacion_dieta/nueva_plato-module";
import { RouterNames } from "@/router/routernames";

@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
  },
})
export default class alimentos_lista extends Vue {
  public created() {
    nueva_platoModule.getnueva_platos();
  }
  public get source() {
    return nueva_platoModule.nueva_platos;
  }

  public get Columns() {
    var Columnas: ClassColumnDataTable[] = [];

    Columnas.push(
      new ClassColumnDataTable(
        "nombre",
        "Nombre",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    return Columnas;
  }

  public editar(e: any) {
    this.$router.push({
      name: RouterNames.adminalimentos_edit,
      params: { id: e.toString() },
    });
  }
}
